<template>
  <div class="equipmentInfo">
    <h1>
      {{ $t('equipmentManage.faultInfo') }}
      <el-button
        v-if="$page.showBtn('EQUIPMENT_MANAGE_VIEW')"
        class="seeView"
        type="text"
        @click="$emit('view', 'view')"
        >{{ $t('base.view') }}</el-button
      >
    </h1>
    <ul>
      <li>
        <div class="list">
          <!-- lang:故障名称 -->
          <div>{{ $t('equipmentManage.faultName') }}</div>
          <div>{{ tableRow.name }}</div>
        </div>
      </li>
      <li>
        <div class="list">
          <!-- lang:故障代码 -->
          <div>{{ $t('equipmentManage.faultNumber') }}</div>
          <div>{{ tableRow.number }}</div>
        </div>
      </li>
      <li>
        <div class="list">
          <!-- lang:所属部件 -->
          <div>{{ $t('equipmentManage.faultPart') }}</div>
          <div>{{ topName }}</div>
        </div>
      </li>
      <!-- <li>
        <div class="list">
          <div>{{ $t('equipmentManage.faultDetail') }}</div>
          <div>
            <el-input
              type="textarea"
              autosize
              v-model="tableRow.detail"
              :disabled="true"
            />
          </div>
        </div>
      </li> -->
      <li>
        <div class="list">
          <!-- lang:解决方案 -->
          <div>{{ $t('equipmentManage.faultSolution') }}</div>
          <div>
            <el-input
              type="textarea"
              autosize
              v-model="tableRow.solution"
              :disabled="true"
            />
          </div>
        </div>
      </li>
      <li>
        <div class="list">
          <!-- lang:故障图片 -->
          <div>{{ $t('equipmentManage.faultImageSet') }}</div>
          <div style="background: transparent; padding: 0">
            <el-image
              v-for="item in tableRow.imageSet"
              :key="item.id"
              :src="item.fileHost + '/' + item.objectName"
              @click="openPreview(item.fileHost + '/' + item.objectName)"
            />
          </div>
        </div>
      </li>
      <li>
        <div class="list">
          <!-- lang:相关附件 -->
          <div>{{ $t('equipmentManage.faultFileSet') }}</div>
          <div style="background: transparent; padding: 0">
            <p v-for="item in tableRow.fileSet" :key="item.id">
              <el-link
                :href="item.fileHost + '/' + item.objectName"
                target="_blank"
                type="primary"
                >{{ item.originalName }}</el-link
              >
            </p>
          </div>
        </div>
      </li>
    </ul>
    <preview-image v-if="showPreview" :src="previewUrl" @close="closePreview" />
  </div>
</template>

<script>
export default {
  props: {
    // 当前选中的表格行数据
    tableRow: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      showPreview: false,
      previewUrl: '',
      topName: '',
    }
  },
  mounted() {
    this.$ajax({
      url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.getEquipmentById,
      data: this.tableRow,
    }).then((data) => {
      this.topName = data.name
    })
  },
  methods: {
    openPreview(url) {
      this.previewUrl = url
      this.showPreview = true
    },
    closePreview() {
      this.showPreview = false
    },
  },
}
</script>